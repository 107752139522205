import React from 'react';

import { ReactComponent as Logo } from '../assets/svg/dw-black.svg';
import { ReactComponent as Burger } from '../assets/svg/burger.svg';
import { ReactComponent as Close } from '../assets/svg/close.svg';

import Links from './links';
import { mobileMenuToggle } from './helpers/func';

import '../styles/navbar.css'

class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        }
    }

    handleHover = () => {
        this.setState({
            isHovered: !this.state.isHovered
        })
    }

    componentDidMount() {
        var st = window.pageYOffset || document.documentElement.scrollTop;
        let lastScrollTop = st <= 0 ? 0 : st;
        document.getElementById('header').setAttribute('data-scroll-position', lastScrollTop);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (e) => {
        var st = window.pageYOffset || document.documentElement.scrollTop;
        let lastScrollTop = document.getElementById('header').getAttribute('data-scroll-position');
        if (st > lastScrollTop) {
            document.getElementById('header').classList.add("hideAnimation")
        } else {
            document.getElementById('header').classList.remove("hideAnimation")
        }
        lastScrollTop = st <= 0 ? 0 : st;
        document.getElementById('header').setAttribute('data-scroll-position', lastScrollTop);
    }


    render() {
        const { isHovered } = this.state;
        const logoClass = isHovered ? "header_logo_imgClip active" : "header_logo_imgClip";
        return (
            <header className="header" id="header" data-scroll-position="0">
               {/*  <DevTitle></DevTitle> */}
                <div className="header_container_full">
                    <div className="header_container">
                        <a href="/" className="header_logo" alt="Dotworld Technologies">
                            <Logo className="header_logo_img" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover} />
                            <Logo className={logoClass} />
                        </a>
                        <Burger className="header_burger" onClick={mobileMenuToggle} />
                        <div className="header_menu">
                            <div className="header_menu_top">
                                <a href="/" className="header_logo" >
                                    <Logo className="header_logo_img" />
                                </a>
                                <Close className="header_close" onClick={mobileMenuToggle} />
                            </div>
                            <div className="header_menu_container">
                                <Links></Links>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Navbar;