import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/letstalk.css'

import { ReactComponent as Arrow } from '../assets/svg/arrow.svg';

function LetsTalk(props) {
    let { heading, subHeading } = props.data;
    return (
        <section className="lets-talk">
            <div className="container">
                <div className="content align-center justify-center">
                    <h4 className="sub">{subHeading}</h4>
                    <h5>
                        <Link to="/contact">
                            {heading}
                            <Arrow />
                        </Link>
                    </h5>
                </div>
            </div>
        </section>
    );
};
export default LetsTalk;