import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import LangContext from "./lang/LangContext";
import lang from "./lang/en-US";

import Transition from "./components/Transition";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import ScrollToTop from "./components/ScrollToTop";
import Cursor from "./components/cursor";
import Careers from "./components/Careers";
import JobDescription from "./components/JobDescription";

import Home from "./pages/home";
import Contact from "./pages/contact";
import Visitor from "./pages/visit";
import Services from "./pages/services";
import ProductDetail from "./pages/product";
import Products from "./pages/products";
import Loading from "./pages/Loading";

import "./styles/App.css";

function App(props) {
  setTimeout(() => {
    let landing = document.getElementById("landing");
    if (landing) {
      landing.classList.remove("-visible");
    }
  }, 2000);
  console.clear();
  return (
    <Router>
      <Loading />
      <LangContext.Provider value={lang}>
        <ScrollToTop>
          <div className="container">
            {/*   <DevTitle/> */}
            <Navbar />
            <div className="dw-loader dw-loader_fill"></div>
            <Transition>
              <Switch>
                <Route path="/about" component={Home} />
                <Route path="/services" component={Services} />
                <Route path="/products/:product" component={ProductDetail} />
                <Route path="/products" component={Products} />
                <Route path="/careers/:title" component={JobDescription} />
                <Route path="/careers" component={Careers} />
                <Route path="/contact" component={Contact} />
                <Route path="/visit/:id" component={Visitor} />
                <Route render={() => <Redirect to="/about" />} />
              </Switch>
            </Transition>
            <Footer />
            <Cursor />
          </div>
        </ScrollToTop>
      </LangContext.Provider>
    </Router>
  );
}

export default App;
