/* eslint-disable */
import React from "react";
import charming from "charming";
import imagesLoaded from "imagesloaded";

import "../styles/services.css";

class Service extends React.Component {
  componentDidMount() {
    demo();
  }

  render() {
    return (
      <div>
        <div className="content__section">
          <div className="content__main">
            <span></span>
            <span>science today </span>
            <span>technology tmrw.</span>
          </div>
          <h2 className="content__text">Services</h2>
        </div>
        <div className="content__section">
          <a className="content__link">
            <img
              loading="lazy"
              className="content__img"
              src="/assets/images/ar.jpg"
              alt="img"
            />
          </a>
          <h2 className="content__text">Augumented Reality</h2>
        </div>
        <div className="content__section">
          <a className="content__link">
            <img
              loading="lazy"
              className="content__img"
              src="/assets/images/vr.jpg"
              alt="img"
            />
          </a>

          {/* <a className="content__link">
                        <model-viewer class="content__3d" src={_3d('./Astronaut.glb')} alt="A 3D model of an astronaut" auto-rotate background-color='#f5f5f5'></model-viewer>
                    </a> */}
          <h2 className="content__text">Virtual Reality</h2>
        </div>
        <div className="content__section">
          <a className="content__link">
            <img
              loading="lazy"
              className="content__img"
              src="/assets/images/ai.jpg"
              alt="img"
            />
          </a>
          <h2 className="content__text">AI & ML</h2>
        </div>
        <div className="content__section">
          <a className="content__link">
            <img
              loading="lazy"
              className="content__img"
              src="/assets/images/robotics.jpg"
              alt="img"
            />
          </a>
          <h2 className="content__text">Robotics</h2>
        </div>
        <div className="content__section">
          <a className="content__link">
            <img
              loading="lazy"
              className="content__img"
              src="/assets/images/iot.jpg"
              alt="img"
            />
          </a>
          <h2 className="content__text">Internet of Things</h2>
        </div>
        <div className="content__section">
          <a className="content__link">
            <img
              loading="lazy"
              className="content__img"
              src="/assets/images/web.jpg"
              alt="img"
            />
          </a>
          <h2 className="content__text">Web & App dev</h2>
        </div>
      </div>
    );
  }
}

function demo() {
  console.log(
    "%cServices page inspired from Akademi\nRewritten by Codrops",
    "background: #3625d3; color: #ffffff"
  );

  const chars = ["∝", "ந்", "#", "@", "&", "§", "β", "α", "▲", "அ"];
  const charsTotal = chars.length;
  const getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  class Entry {
    constructor(el) {
      this.DOM = { el: el };
      this.DOM.image = this.DOM.el.querySelector(".content__img");
      this.DOM.title = { word: this.DOM.el.querySelector(".content__text") };
      charming(this.DOM.title.word);
      this.DOM.title.letters = Array.from(
        this.DOM.title.word.querySelectorAll("span")
      );
      this.DOM.title.letters.forEach(
        (letter) => (letter.dataset.initial = letter.innerHTML)
      );
      this.lettersTotal = this.DOM.title.letters.length;
      observer.observe(this.DOM.el);
    }
    enter(direction = "down") {
      this.DOM.title.word.style.opacity = 1;

      this.timeouts = [];
      this.complete = false;
      let cnt = 0;
      this.DOM.title.letters.forEach((letter, pos) => {
        const timeout = setTimeout(() => {
          letter.innerHTML = chars[getRandomInt(0, charsTotal - 1)];
          setTimeout(() => {
            letter.innerHTML = letter.dataset.initial;
            ++cnt;
            if (cnt === this.lettersTotal) {
              this.complete = true;
            }
          }, 100);
        }, pos * 80);
        this.timeouts.push(timeout);
      });
    }
    exit(direction = "down") {
      this.DOM.title.word.style.opacity = 0;
      if (this.complete) return;
      for (let i = 0, len = this.timeouts.length; i <= len - 1; ++i) {
        clearTimeout(this.timeouts[i]);
      }
    }
  }

  let observer;
  let current = -1;
  let allentries = [];
  const sections = Array.from(document.querySelectorAll(".content__section"));
  // Preload all the images in the page..
  imagesLoaded(document.querySelectorAll(".content__img"), () => {
    document.body.classList.remove("loading");
    if ("IntersectionObserver" in window) {
      document.body.classList.add("ioapi");

      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > 0.5) {
              const newcurrent = sections.indexOf(entry.target);
              if (newcurrent === current) return;
              const direction = newcurrent > current;
              if (current >= 0) {
                allentries[current].exit(direction ? "down" : "up");
              }
              allentries[newcurrent].enter(direction ? "down" : "up");
              current = newcurrent;
            }
          });
        },
        { threshold: 0.5 }
      );

      sections.forEach((section) => allentries.push(new Entry(section)));
    }
  });
}

export default Service;
