import React from 'react';

import LangContext from '../lang/LangContext';

import LandingText from '../components/landing';
import LandingDesign1 from '../components/landing1';
import Friends from '../components/Friends'
import Clients from '../components/clients';
import Background from '../components/background';
import LetsTalk from '../components/LetsTalk';

import { closeMenu } from '../components/helpers/func';

import '../styles/home.css'
import Video from '../components/Video';

class Home extends React.PureComponent {
    componentDidMount() {
        closeMenu();
    }

    getRandomLanding = (prop) => {
        let random = Math.ceil(Math.random() * 2);
        switch (random) {
            case 1:
                return <LandingText data={prop.landing} />
            case 2:
                return <LandingDesign1 data={prop.landing} />
            default:
                return <LandingText data={prop.landing} />
        }
    }

    render() {
        let { home, letsTalk } = this.context;
        return (
            <main className="main_content">
                {this.getRandomLanding(home)}
                <Video/>
                <Friends data={home.friends}></Friends>
                <Clients data={home.clients}></Clients>
                {/*    <Button redirect="/products">Show more</Button> */}
                <LetsTalk data={letsTalk}></LetsTalk>

                <Background></Background>
            </main >
        );
    }
}
Home.contextType = LangContext;
export default Home;