import React, { useContext, useEffect } from 'react';

import Background from '../components/background';
import ProductList from '../components/ProductList';
import LangContext from '../lang/LangContext';


import { closeMenu } from '../components/helpers/func';
import '../styles/product.css';


function Products(props, context) {
    closeMenu();

    let { products } = useContext(LangContext);
    useEffect(() => {document.title = `Products - Dotworld Technologies`});

    
    return (
        <main className="main_content">
            <section className="products">
                <ProductList products={products} />
            </section>
            <Background />
        </main>
    )

}

export default Products;