import React from 'react';
import { withRouter } from "react-router-dom";
import {  CSSTransition } from "react-transition-group";

import '../styles/transition.css'

function Transition({ location, children }) {
    return (

        <CSSTransition
            in={true}
            unmountOnExit
            key={location.key}
            timeout={300}
            classNames="fade">
            {children}
        </CSSTransition>

    )
}

export default withRouter(Transition);