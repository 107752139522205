import React from 'react';

import '../styles/video.css'

export default function Video(props) {
    return (
        <section className="section section-partners">
            <div className="partners" >
                <div className="partners_title">
                    <p className="partners_title_text">
                       Works
                    </p>
                </div>
                <div className="partners__content" style={{textAlign:"center"}}>
                    <iframe className="video_frame"
                        title='video_frame'
                        src='https://www.youtube.com/embed/Jit0R8e0kr0?rel=0&modestbranding=1&embed_config={"adsConfig":{"nonPersonalizedAd":false},"relatedChannels":[],"disableRelatedVideos":true}&enablejsapi=1&origin=https://dotworld.in&widgetid=1'
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </section>

    )
}