import React from 'react';
import { sendEmail } from '../components/helpers/func';

import Home from './home';

class Visitor extends React.PureComponent {
    async componentWillMount() {
        let id = this.props.match ? this.props.match.params.id : "";
        if (id) {
            await sendEmail({
                name: "New Visitor",
                email: 'noreply@dotworld.in',
                description: `A new vistor has loaded the site with id : ${id}`,
            })
                .catch(error => console.error(error));
        }
    }

    render() {
        return (
            <Home></Home>
        );
    }
}

export default Visitor;