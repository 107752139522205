import React, { useEffect, useState } from "react";
import "../styles/JobDescription.css";
import axios from "axios";
import moment from "moment";

const JobDescription = (props) => {
  const [jobdetails, setJobdetails] = useState("");

  const job = props.match.params.title;

  function getJobDetail() {
    axios
      .get(`https://dotworld.erpnext.com/api/resource/Job Opening/${job}`)
      .then((res) => setJobdetails(res.data.data))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getJobDetail();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {jobdetails && (
        <div className="jd_container">
          <section>
            <div className="jd_heading">
              <div className="job_title">
                {jobdetails.job_title}
                {/* <span className="subtitle">
                  {"  "}Posted {moment(jobdetails.creation).fromNow()}
                </span> */}
              </div>
              <div class="explain-bar">
                <div class="explain-contents">
                  <div class="explain-title">Experience</div>
                  <div class="explain-subtitle">
                    Minimum {jobdetails.maximum_experience}{" "}
                    {jobdetails.maximum_experience === 1 ? "year" : "years"}
                  </div>
                </div>
                <div class="explain-contents">
                  <div class="explain-title">Role</div>
                  <div class="explain-subtitle">{jobdetails.designation}</div>
                </div>
                <div class="explain-contents">
                  <div class="explain-title">Salary up to</div>
                  <div class="explain-subtitle">
                    ₹ {(jobdetails.upper_range * 12).toLocaleString("en-IN")} PA
                  </div>
                </div>
                {jobdetails.vacancy > 0 && (
                  <div class="explain-contents">
                    <div class="explain-title">Vacancy</div>
                    <div class="explain-subtitle">{jobdetails.vacancy}</div>
                  </div>
                )}
                <div class="explain-contents">
                  <div class="explain-title">Posted</div>
                  <div class="explain-subtitle">
                    {moment(jobdetails.creation).fromNow()}
                  </div>
                </div>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: jobdetails.description }}
            ></div>
          </section>

          <div style={{ textAlign: "center" }}>
            <a
              href="https://dotworld.erpnext.com/dw_job_application"
              target="blank"
            >
              <button type="button" className="btn fill">
                Apply
              </button>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default JobDescription;
