import React from "react";
import { useHistory } from "react-router-dom";
import { showOverlay, hideOverlay, setOverlayColor } from "./helpers/func";

const JobList = ({ job }) => {
  let history = useHistory();

  return (
    <>
      <div
        class="callout purple"
        onClick={(e) => {
          if (job.redirect) {
            return window.open(job.redirect, job.title);
          }
          setOverlayColor(e.currentTarget.getAttribute("data-product-color"));
          showOverlay();
          setTimeout(() => {
            history.push(`careers/${job.name}`);
            setTimeout(() => hideOverlay(), 450);
          }, 450);
        }}
        data-product-color="red"
      >
        <h3>{job.job_title}</h3>
        <p>
          Type : {job.job_type}{" "}
          <span>
            Experience : Min. {job.minimum_experience}{" "}
            {job.minimum_experience === 1 ? "Year" : "Years"}
          </span>
        </p>
      </div>
    </>
  );
};

export default JobList;
