import React, { useContext, useEffect, useRef } from "react";
import { useParams, Redirect } from "react-router-dom";

import LangContext from "../lang/LangContext";
import { ReactComponent as Share } from "../assets/social/share.svg";

import "../styles/product.css";

function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

function ProductDetail() {
  let { product } = useParams();
  let result = useContext(LangContext).products.filter(
    (p) => p.key === product
  );

  useEffect(() => {
    document.title = `${result.title} - Dotworld Technologies`;
  });
  useEffect(() => {
    try {
      document.querySelector("h1.dw-variable-font").innerHTML = document
        .querySelector("h1.dw-variable-font")
        .textContent.split("")
        .map((a) => `<span class="dw-variable-letter">${a}</span>`)
        .join("");
    } catch (e) {}
  }, []);

  let getXPercentage = (x) => {
    return x / window.innerWidth;
  };
  let ytoptions =
    "?rel=0&controls=0&disablekb=1&fs=0&loop=1&modestbranding=1&playsinline=1&color=white&showinfo=0";

  const handler = ({ clientX }) => {
    let words = document.querySelectorAll("span.dw-variable-letter");
    let xPercentage = getXPercentage(clientX);
    let fontWeight = 700 * xPercentage + 100;

    words.forEach((letter, i) => {
      setTimeout(function () {
        letter.style.fontVariationSettings = "'wght' " + fontWeight;
      }, 80 * (i * 0.6));
    });
  };

  // Add event listener using our hook
  useEventListener("mousemove", handler);

  const share = ({
    title = "Product of Dotworld Technologies",
    text = "Hey! Look what I found!",
  }) => {
    if (navigator.share) {
      navigator
        .share({ title, text, url: window.location.pathname })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  if (result.length !== 1) {
    console.error("Product detail not found");
    return <Redirect to="/products" />;
  }
  result = result[0];

  return (
    <div className="dw-main">
      <section className="dw-container top dw-wrapper">
        <div className="dw-product">
          <div className="dw-name">
            <div className="dw-product-heading">
              <h1 className="dw-variable-font" style={{ display: "inline" }}>
                {result.title}
              </h1>
              {result.share && (
                <Share
                  className="dw-product-share-btn"
                  onClick={() =>
                    share({
                      title: `${result.title} - Product of Dotworld Technologies`,
                      text: `Hi there,\n\n${result.share}\nVisit : ${window.location.href} for more details.\n\nGive us a call or visit us. Details on https://g.page/Dotworld?gm .\nAlso don't forget to give us your feedback @ https://g.page/Dotworld/review?rc .\n\n\nThanks,\nDotworld Technologies.`,
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="dw-f-one-part">
          {result.landingImage && (
            <picture>
              <source media="(max-width: 992px)" srcSet={result.landingImage} />
              <img
                loading="lazy"
                className="dw-f-one"
                src={result.landingImage}
                alt={result.title}
              />
            </picture>
          )}
        </div>
        <div className="dw-about">
          <div className="dw-para">
            <p className="dw-content-text">
              {result.detailed !== undefined && result.detailed.para1}
            </p>
          </div>

          <div className="dw-desc">
            <p className="dw-cap">
              <strong>Technologies</strong>
            </p>
            <ul className="dw-list">
              {Array.isArray(result.description)
                ? result.description.map((res, index) => (
                    <li key={index}>{res}</li>
                  ))
                : result.description}
            </ul>
          </div>
        </div>
      </section>

      {result.grid !== undefined && result.grid.length > 1 && (
        <section className="dw-container-yola">
          <div className="dw-container dw-wrapper">
            <div className="dw-grid-wrapper">
              {result.grid.map((g, index) => (
                <div className="dw-box a" key={`grid-${index}`}>
                  <img loading="lazy" src={g.image} alt={g.alt} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {result.detailed !== undefined && result.detailed.para2 && (
        <section className="dw-container  dw-wrapper">
          <div className="dw-content">
            <p className="dw-content-text">
              {result.detailed !== undefined && result.detailed.para2}
            </p>
          </div>
        </section>
      )}

      {result.video !== undefined && (
        <section className="dw-container dw-wrapper">
          <div className="dw-part dw-video">
            <video autoPlay loop muted preload="auto">
              <source src={result.video} />
            </video>
          </div>
        </section>
      )}

      {result.youtube !== undefined && (
        <section className="dw-container dw-wrapper">
          <div className="dw-part dw-video dw-youtube">
            <iframe
              width="720"
              height="405"
              title={result.key}
              src={result.youtube + ytoptions}
              frameBorder="0"
              allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </section>
      )}

      <section className="dw-container dw-wrapper">
        <div className="dw-content">
          <p className="dw-content-text">
            {result.detailed !== undefined && result.detailed.para3}
          </p>
        </div>
      </section>
    </div>
  );
}

export default ProductDetail;
