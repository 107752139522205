import React from "react";

import "../styles/clients.css";

var images = require.context("../assets/", true);

function Clients(props) {
  let { title, list } = props.data;
  return (
    <section className="section section-partners">
      <div className="partners">
        <div className="partners_title">
          <p className="partners_title_text">{title}</p>
        </div>
        <div className="partners__content">
          <div className="partners__slider">
            <div className="slick-list">
              <div className="slick-track">
                <div className="partners__list">
                  {list.map((client, index) => (
                    <span key={index} className="partner">
                      <span className="left-top"></span>
                      <span className="right-bottom"></span>
                      <img
                        loading="lazy"
                        className="partner__image"
                        src={client.image}
                        alt={client.alt}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
