import React from 'react';

import Product from './Product';

import '../styles/product.css';


function ProductList(props) {
    return (
        <section className="section section__products">
            <div className="wrapper wrap-padding">
                <h1 className="products_title">Our Products</h1>
                <div className="list">
                    {
                        props.products.map((product, index) => (
                            <Product key={index} data={product}></Product>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default ProductList;