const lang = {
  home: {
    landing: {
      left: "Technologies",
      leftDynamic: ["Customized.", "Invented."],
      right: [
        "We are a creative team of robotics engineers, design enthusiasts and innovationeers.",
        "We created Dotworld to be a dynamic ecosystem to learn and grow as professionals, and to celebrate the passion of solving challenges using cutting-edge technology.",
      ],
    },
    friends: {
      title: "We grew up together",
      list: [
        {
          link: "https://www.katomaran.com",
          alt: "Katomaran",
          image: "./assets/clients_c/katomaran.svg",
        },
        // {
        //   link: "http://www.infinitydots.in",
        //   alt: "Infinity Dots",
        //   image: "./assets/clients_c/infinitydots.svg",
        // },
      ],
    },
    clients: {
      title: "Some of our clients...",
      list: [
        {
          alt: "Nippon Paints",
          image: "./assets/clients_c/nippon.svg",
        },
        {
          alt: "Vijay TV",
          image: "./assets/clients_c/vijay.svg",
        },
        {
          alt: "Security Intel",
          image: "./assets/clients_c/sis.svg",
        },
        {
          alt: "Indian Army",
          image: "./assets/clients_c/indian-army.svg",
        },
        {
          alt: "Interlock",
          image: "./assets/clients_c/interlock.svg",
        },
        {
          alt: "Titan Watches",
          image: "./assets/clients_c/titan.svg",
        },
        {
          alt: "Amco Saft",
          image: "./assets/clients_c/amco-saft.svg",
        },
        {
          alt: "Black Sheep",
          image: "./assets/clients_c/blacksheep.svg",
        },
        // {
        //     alt: "PlayGateway",
        //     image: "./clients_c/playgateway.svg"
        // },
        {
          alt: "Indo-Min",
          image: "./assets/clients_c/indo-mim.svg",
        },
        {
          alt: "Sairam",
          image: "./assets/clients_c/sairam.svg",
        },
      ],
    },
    services: {
      title: "services",
      list: [
        {
          title: "AR",
        },
        {
          title: "VR",
        },
        {
          title: "AI",
        },
        {
          title: "ML",
        },
        {
          title: "Robotics",
        },
        {
          title: "IoT",
        },
        {
          title: "Web",
        },
      ],
    },
  },
  letsTalk: {
    subHeading: "We do a lot, but we didn't say much",
    heading: "Let's Talk.",
  },
  menu: {
    items: [
      {
        title: "About",
        link: "/about",
      },
      {
        title: "Services",
        link: "/services",
      },
      {
        title: "Products",
        link: "/products",
      },
      {
        title: "Contact",
        link: "/contact",
      },
    ],
  },
  info: {
    address: `SF No:-348-1A3, Ground Floor, 
                    Sri Lakshmi Nagar II,
                    Thanneer Pandhal Road, Peelamedu, 
                    Coimbatore - 641004, Tamil Nadu, India`,
    mobile: "+91 88070 44987",
  },
  products: [
    {
      key: "hotel-robots",
      title: "Hotel Robot",
      shortDesc: "Delivery amenities to your guest fast and quick.",
      share: "Delivery amenities to your guest fast and quick. Check this out!",
      description: ["HOTEL_ROBOT", "DELIVERY_ROBOT", "HOTEL"],
      image: "/assets/images/hotel_robot/hotel_robot_grid1.webp",
      credit: "Photo by Dotworld Technologies",
      link: "/products/hotel-robots",
      color: "#ff5739",
      landingImage: "/assets/images/hotel_robot/hotel_robot_grid1.webp",
      detailed: {
        para1:
          "Our room service robot's main function is to deliver items from the service desk or store room to the guest's door. It can travel autonomously and dock itself to charge when it needs to. It can be accessed by the Digital Concierge app and is also compatible with our Vending Machine.",
        para2:
          "Our vending machine automatically connects with the hotel robot to dispense the specific item to it. It has three delivery points for the three trays that the hotel robot has. It is fully automated, from collecting the orders to releasing the items that range from food items to soaps, towels and other amenities.",
      },
      grid: [
        {
          image: "/assets/images/hotel_robot/hotel_robot_grid1.webp",
          alt: "hotel robot",
        },
        {
          image: "/assets/images/hotel_robot/hotel_robot_grid2.webp",
          alt: "hotel robot with vending machine",
        },
        {
          image: "/assets/images/hotel_robot/hotel_robot_grid3.webp",
          alt: "hotel robot with user",
        },
        {
          image: "/assets/images/hotel_robot/hotel_robot_grid4.webp",
          alt: "hotel robot docking",
        },
      ],
    },
    {
      key: "dokat-robots",
      title: "DoKat Robots",
      shortDesc:
        "Let us keep our best foot forward in fighting for this noble cause.!",
      share:
        "Let us keep our best foot forward in fighting for this noble cause!.\nWe have joined hands with Aries Biomed and Katomaran to tackle the life-threatening COVID - 19 by automating the process flow of the medics.",
      description: ["AURA", "CURA", "CURA PLUS"],
      image: "/assets/images/dokat/dokat_main.jpg",
      credit: "Photo by Tim Carey on Unsplash",
      link: "/products/dokat-robots",
      color: "#ff5739",
      landingImage: "/assets/images/dokat/dokat_main.jpg",
      youtube: "https://www.youtube.com/embed/9ryWGNcoH1c",
      detailed: {
        para1:
          "We have joined hands with Aries Biomed and Katomaran to tackle the life-threatening COVID - 19 by automating the process flow of the medics.",
        para2:
          "Ultraviolet germicidal irradiation (UVGI) is a disinfection method that uses short-wavelength ultraviolet (UV-C) light to kill or inactivate microorganisms. This technology has been used in water treatment and sanitisation in hospitals.",
        para3:
          "Doctors, nurses and other medical staff have been our frontline soldiers in this fight against the deadly novel coronavirus, and they are most at risk. The risk factor has also been dangerously high due to the lack of adequate personal protective equipment (PPE).",
      },
    },
    {
      key: "f1-motion-sim",
      title: "F1 Motion Simulator",
      shortDesc: "F1 Motion Sim uses VR to simulate the experience",
      description: ["Unreal Engine 4", "Windows", "Game", "Motion Simulation"],
      share:
        "Check this F1 Motion Simualtor. It can be customized to your needs 😀. ",
      image: "/assets/images/f1.jpg",
      credit: "Photo by Tim Carey on Unsplash",
      link: "/products/f1-motion-sim",
      color: "#ff5739",
      landingImage: "/assets/images/f1/f1_home.jpg",
      grid: [
        {
          image: "/assets/images/f1/f1_grid1.jpg",
          alt: "f1 race start location",
        },
        {
          image: "/assets/images/f1/f1_grid2.jpg",
          alt: "customer trying out simulator",
        },
        {
          image: "/assets/images/f1/f1_grid3.jpg",
          alt: "installation of simulator",
        },
        {
          image: "/assets/images/f1/f1_grid4.jpg",
          alt: "another customer playing f1 simulator",
        },
      ],
      detailed: {
        para1:
          "The F1 motion simulator is a unique and stylish full motion simulator aimed for professional home or enterprise use. Just like the F-VR2 and F-VR3, it's a hand made and custom built race simulator.whether your a race driver practicing the best race lines, an enthusiast feeling the ultimate racing thrill at home, or a commercial enterprise, the F-VR1 adds a massive sense of realism of actually being out there on the track!",
        para2: "",
      },
    },
    {
      key: "flight-motion-sim",
      title: "Flight Motion Simulator",
      description: ["Flight Simulator", "Unreal", "2 DOF"],
      shortDesc: "",
      share:
        "Check this Flight Motion Simualtor. It can be customized to your needs 😀. ",
      image: "/assets/images/flight.jpg",
      link: "/products/flight-motion-sim",
      color: "#3949AB",
      grid: [
        {
          image: "/assets/images/flight/flight_grid1.jpg",
          alt: "flight simulator image",
        },
        {
          image: "/assets/images/flight/flight_grid2.jpg",
          alt: "flight simulator image",
        },
        {
          image: "/assets/images/flight/flight_grid3.jpg",
          alt: "flight simulator image",
        },
        {
          image: "/assets/images/flight/flight_grid4.jpg",
          alt: "flight simulator image",
        },
      ],
      detailed: {
        para1: "",
        para2: "",
      },
    },
    {
      key: "hotair-balloon",
      title: "Hot Air Balloon",
      shortDesc: "",
      description: ["Hot Air Balloon", "Unreal Engine"],
      image: "/assets/images/balloon.jpg",
      share:
        "Check this Hot Air Balloon Experience. It can be customized to your needs 😀. ",
      link: "/products/hotair-balloon",
      color: "#FFE634",
      landingImage: "/assets/images/hotair_balloon/balloon_home.png",
      youtube: `https://www.youtube.com/embed/p0jvy9wyf9c`,
      grid: [
        {
          image: "/assets/images/hotair_balloon/balloon_grid1.jpg",
          alt: "f1 race start location",
        },
        {
          image: "/assets/images/hotair_balloon/balloon_grid2.jpg",
          alt: "customer trying out simulator",
        },
        {
          image: "/assets/images/hotair_balloon/balloon_grid3.jpg",
          alt: "installation of simulator",
        },
        {
          image: "/assets/images/hotair_balloon/balloon_grid4.jpg",
          alt: "another customer playing f1 simulator",
        },
      ],
      detailed: {
        para1: "",
        para2: "",
      },
    },
    {
      key: "dotkeeper",
      title: "DotKeeper",
      shortDesc: "",
      description: ["Goalkeeper", "AI", "Video Analytics"],
      share:
        "Check this Goalkeeper. Affordable, High Precision, Sturdy build. It can be customized to your needs 😀. ",
      image: "/assets/images/goal.jpg",
      link: "/products/dotkeeper",
      color: "#E5BF1B",
      landingImage: "/assets/images/dotkeeper/goal_home.jpg",
      youtube: "https://www.youtube.com/embed/ViwTGH92ihA",
      grid: [
        {
          image: "/assets/images/dotkeeper/goal_grid1.jpg",
          alt: "dotkeeper pics",
        },
        {
          image: "/assets/images/dotkeeper/goal_grid2.jpg",
          alt: "dotkeeper pics",
        },
        {
          image: "/assets/images/dotkeeper/goal_grid3.jpg",
          alt: "dotkeeper pics",
        },
        {
          image: "/assets/images/dotkeeper/goal_grid4.jpg",
          alt: "dotkeeper pics",
        },
      ],
      detailed: {
        para1:
          "Dot Keeper is robotic arm which senses the Football by high speed camera. Player kick the ball as fast as they can. the intelligence software maps the trajectory motion of the ball and the Robotic arm swings within goal post to save the goal.",
        para2: "",
      },
    },
    {
      key: "dotwaiter",
      title: "DotWaiter",
      shortDesc: "",
      description: ["Restro Robo", "Guided Robot", "AI"],
      image: "/assets/images/servicebot.jpg",
      share:
        "Check this Service Robot. Affordable, Sturdy build with lots of features. It can be customized to your needs 😀. ",
      link: "/products/dotwaiter",
      color: "#2a6fa2",
      landingImage: "/assets/images/dotwaiter/dotwaiter_home.jpg",
      grid: [
        {
          image: "/assets/images/dotwaiter/dotwaiter_grid1.jpg",
          alt: "dotwaiter pics",
        },
        {
          image: "/assets/images/dotwaiter/dotwaiter_grid2.jpg",
          alt: "dotwaiter pics",
        },
        {
          image: "/assets/images/dotwaiter/dotwaiter_grid3.jpg",
          alt: "dotwaiter pics",
        },
        {
          image: "/assets/images/dotwaiter/dotwaiter_grid4.jpg",
          alt: "dotwaiter pics",
        },
      ],
      detailed: {
        para1:
          "The robots run along photosensitive strips on the ground. They can carry trays of food, greet customers and even communicate through a series of simple phrases. With optical or magnetic sensors, the robots can know exactly where to stop in the restaurant, returning to the kitchen when the food is delivered.",
        para2:
          "The robot follows a circular track towards assigned seats after seat numbers are given on a remote control and the time it takes to serve can be adjusted if the customer's in a hurry. When they arrive at a specific seat, the robots turn to the customer and greet the person politely with customised message. Then, after the guests get their food from the tray, the robot says, 'Please touch my head if you don' t need anything else'. When the customer gives a pat on their heads, the robots automatically head for another guest. ",
      },
    },
    {
      key: "dotteleport",
      title: "DotTeleport",
      shortDesc: "Your imagination comes true with our 'Dot AR' technologies",
      description: ["AR", "Teleport", "Customizable", "Interactive"],
      image: "/assets/images/teleport.jpg",
      link: "/products/dotteleport",
      color: "#F44336",
      landingImage: "/assets/images/teleport/teleport_home.jpg",
      grid: [
        {
          image: "/assets/images/teleport/teleport_grid1.jpg",
          alt: "customers pic using dot teleport",
        },
        {
          image: "/assets/images/teleport/teleport_grid2.jpg",
          alt: "customers pic using dot teleport",
        },
        {
          image: "/assets/images/teleport/teleport_grid3.jpg",
          alt: "customers pic using dot teleport",
        },
        {
          image: "/assets/images/teleport/teleport_grid4.jpg",
          alt: "customers pic using dot teleport",
        },
      ],
      detailed: {
        para1:
          "Nippon teleport is Augmented reality photo generating product which teleports guest / users to different location. Customized locations include the Nippon blobby which mimics the user actions. Motion capturing is used to interact with the Nippon blobby. User can teleport to the desired location and take a printed image as a gift. ",
        para2:
          "This is a motion capturing-based boxing game, where user motion used as a game input for the play. User actions are captured by motion tracking devices and the same is fed into the game play. Here the user is captured as Nippon paint blobby that has to fight against the enemies named “Sun” and “Dust Demon”. Nippon blobby mimic user motion to fight with opponents like Atom in Real Steel. User fight in the real world and blobby will follow us in the augmented world. At last we have to defeat and win the game to get an exciting price.            ",
      },
    },
    {
      key: "roboface",
      title: "Humanoid (under research)",
      shortDesc: "Realistic humaniod for research and development",
      description: ["Robot", "Face", "Humanoid", "R&D"],
      share:
        "Check this R&D project. It looks like real human. It can be customized to your needs 😀. ",
      image: "/assets/images/roboface/roboface.jpg",
      link: "/products/roboface",
      color: "#d2efff",
      landingImage: "/assets/images/roboface/roboface.jpg",
      grid: [
        {
          image: "/assets/images/roboface/roboface_grid1.jpg",
          alt: "robo face side pose with skin",
        },
        {
          image: "/assets/images/roboface/roboface_grid2.jpg",
          alt: "robo face straight pose",
        },
        {
          image: "/assets/images/roboface/roboface_grid3.jpg",
          alt: "robo face side pose without skin",
        },
        {
          image: "/assets/images/roboface/roboface_grid4.jpg",
          alt: "comparison of then and now",
        },
      ],
      detailed: {
        para1: "",
        para2: "",
      },
    },
    {
      key: "mobilerobots",
      title: "Autonomous Mobile Robots",
      shortDesc: "",
      description: ["Robot", "Autonomous Navigation", "ROS"],
      image: "/assets/images/mobilerobots/mobilerobot0.jpg",
      link: "/products/mobilerobots",
      color: "#d2efff",
      landingImage: "/assets/images/mobilerobots/mobilerobot0.jpg",
      grid: [
        {
          image: "/assets/images/mobilerobots/mobilerobot_grid01.jpg",
          alt: "",
        },
        {
          image: "/assets/images/mobilerobots/mobilerobot_grid02.jpg",
          alt: "",
        },
        {
          image: "/assets/images/mobilerobots/mobilerobot_grid03.jpg",
          alt: "",
        },
        {
          image: "/assets/images/mobilerobots/mobilerobot_grid04.jpg",
          alt: "",
        },
      ],
      detailed: {
        para1:
          "Our Mobile Robots can autonomously navigate through a completely new environment based on a simple setup procedure. These Robots have a strong base of high payload pulling capacity still retaining the same performance between zero load and full load use cases.",
        para2:
          "Mobile Robots are becoming a clear necessity breaching industries like retail, logistics and hotels. Our Mobile robots are systems carrying autonomous navigation functionality and can move from one place to another, overcoming the obstacles on their path. A simple application layer can be built on top of these robots to transform them into a delivery boy, human follower, security robot or even a racing car",
      },
    },
    {
      key: "kiot",
      title: "KIoT",
      shortDesc: "Windmill management system",
      description: "IoT | Windmill | Data Analytics | Reporting",
      image: "/assets/images/kiot.jpg",
      redirect:
        "https://4c1421c0-9bff-4ee9-b826-c23654b3269d.filesusr.com/ugd/ce12fb_10d791a0414743158935795534b467f0.pdf",
    },
    {
      key: "people-counter",
      title: "People Counter",
      shortDesc: "",
      description: "Video Analytics | IoT | Machine Learning",
      image: "/assets/images/va.jpg",
      redirect: "https://www.katomaran.com",
      credit: "Photo by Paweł Czerwiński on Unsplash",
    },
    {
      key: "saas-va",
      title: "SaaS based Video Analytics",
      shortDesc: "",
      description:
        "Connect any IP Camera to our SaaS service to get insight of what the camera is seeing",
      image: "/assets/images/saas.jpg",
      redirect: "https://www.katomaran.com",
    },
  ],
};

export default lang;
