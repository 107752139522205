import React from "react";

import "../styles/friends.css";

function Friends(props) {
  let { title, list } = props.data;
  return (
    <section className="section section-friends">
      <div className="friends">
        <div className="friends_title">
          <p className="friends_title_text">{title}</p>
        </div>
        <div className="friends__content">
          <div className="friends__list">
            {list.map((friend, index) => {
              return (
                <a
                  rel="noopener noreferrer"
                  target={"_blank"}
                  href={friend.link}
                  key={index}
                  className={friend.alt.toLowerCase()}
                >
                  <img
                    loading="lazy"
                    className="partner__image"
                    src={friend.image}
                    alt={friend.alt}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Friends;
