import React from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import image from "../assets/images/members.jpg";
import image1 from "../assets/images/members1.jpg";
import image2 from "../assets/images/members2.jpg";

const sliderArray = [
  {
    imgUrl: image,
  },
  {
    imgUrl: image1,
  },
  {
    imgUrl: image2,
  },
];

function Slider() {
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  return (
    <div className="slider-container">
      <AutoplaySlider
        mobileTouch
        play={true}
        bullets={true}
        infinite
        interval={6000}
      >
        {sliderArray.map((img, index) => (
          <div className="slider-image-container" key={index}>
            <img src={img.imgUrl} alt="Cover" />
          </div>
        ))}
      </AutoplaySlider>
    </div>
  );
}

export default Slider;
