import React from 'react';

import '../styles/links.css'

import { NavLink } from 'react-router-dom';

function Links() {
    return (
      <ul className="link_menu">
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <a
            href="https://medium.com/@dotworld"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </li>
        <li>
          <NavLink to="/services">Services</NavLink>
        </li>
        <li>
          <NavLink to="/products">Products</NavLink>
        </li>
        <li>
          <NavLink to="/careers">Careers</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
      </ul>
    );
}

export default Links;
