import React, { useEffect, useState } from "react";
import "../styles/Careers.css";
import JobList from "./JobList";
import axios from "axios";
import Slider from "../components/Slider";
import { closeMenu } from "./helpers/func";

const Careers = () => {
  const [jobslist, setJobslist] = useState([]);
  closeMenu();

  useEffect(() => {
    document.title = `Jobs - Dotworld Technologies`;
  });

  useEffect(() => {
    getList();
  }, []);

  async function getList() {
    try {
      let resp = await axios({
        url: `https://dotworld.erpnext.com/api/method/job-opening`,
        method: "GET",
      });
      setJobslist(resp.data.data);
    } catch (e) {
      setJobslist([]);
      console.error(e);
    }
  }

  return (
    <div className="dw-career">
      <div className="career-container narrow hero_container">
        <h1 className="hero_heading">Careers</h1>
        <p className="hero_text text">
          We are looking for people that share our vision. Are you adaptable,
          driven, and friendly? Do you thrive in a fast-paced work environment
          where collaboration is the norm? If the answer is yes, then you’re a
          one and we want you.
        </p>
      </div>
      <div className="slider-container">
        <Slider />
      </div>
      <div className="career-container narrow">
        <p className="text mb">
          We love you and want to ensure that you are supported at work and at
          home. That’s what helps us maintain our coveted best work-life
          balance. Here are just some of the perks you can expect to receive
          while working for us.
        </p>
        <ul className="company_features mb">
          <li>
            <i className="fas fa-check tick"></i>Competitive salary
          </li>
          <li>
            <i className="fas fa-check"></i>Health benefits
          </li>
          <li>
            <i className="fas fa-check"></i>Flexible schedule
          </li>
          <li>
            <i className="fas fa-check"></i>Regular outings
          </li>
          <li>
            <i className="fas fa-check"></i>Career growth
          </li>
          <li>
            <i className="fas fa-check"></i>Paid Time Off
          </li>
        </ul>
      </div>
      <div className="career-container">
        <h1 className="job_header">Current Opening</h1>
        <div className="job_container">
          <div class="job-overview-cards">
            {jobslist.length ? (
              jobslist.map((data, index) => (
                <div key={index}>
                  <JobList job={data} />
                </div>
              ))
            ) : (
              <div className="no-opening">
                Currently we don't have any openings. Kindly stay tuned for
                updates
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="career-container "></div>
    </div>
  );
};

export default Careers;
