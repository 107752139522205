import React from 'react';
import { useHistory } from "react-router-dom";
import { showOverlay, hideOverlay, setOverlayColor } from './helpers/func';

import '../styles/product.css';

function Product(props) {
    const { data } = props;

    let history = useHistory();


    const style = {
        backgroundImage: "url(" + data.image + ")"
    }
    return (
        <div onClick={(e) => {
            if (data.redirect) {
                return window.open(data.redirect, data.title);
            }
            setOverlayColor(e.currentTarget.getAttribute('data-product-color'));
            showOverlay();
            setTimeout(() => {
                history.push(data.link)
                setTimeout(() => hideOverlay(), 200)
            }, 600);

        }} className="product show" data-product-color={data.color}>
            <div className="img enter-y delay-3">
                <div className="bg" style={style}></div>
            </div>
            <div className="text">
                <div className="title enter-y delay-6">
                    <h5 className="c-grey">{data.title}</h5>
                </div>
                <div className="sub-title enter-y delay-9">
                    <p className="c-lightgrey">
                        {data.shortDesc}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Product;