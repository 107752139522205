import React from 'react';
import Typed from 'react-typed';

import '../styles/landing.css'

function LandingText(props) {
    let { left, leftDynamic, right } = props.data;
    return (
        <section className="landing_section">
            <div className="landing_container">
                <div className="container_content">
                    <div className="landing_main_container">
                        <div className="landing_left">
                            <p>{left}
                                <strong ><span className="select">
                                    <Typed
                                        strings={leftDynamic}
                                        typeSpeed={50}
                                        startDelay={500}
                                        backDelay={2000}
                                        backSpeed={50}
                                        showCursor={false}
                                        attr="value"
                                        loop >
                                        <input disabled className="select"  type="text" name="custom_text" />
                                    </Typed>
                                </span>
                                </strong>
                            </p>
                        </div>
                        <div className="landing_right">
                            {
                                right.map((data, index) => (
                                    <h1 key={index}>{data}</h1>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LandingText;