import React from 'react';

import Typed from 'react-typed';
import '../styles/landing.css'
import '../styles/landing1.css'

let imageLeft = ["f1", "servicebot"];
let imageRight = ["vr", "web", "flight"];

function getRandomImage(images) {
    let random = Math.ceil(Math.random() * images.length);
    return images[random - 1];
}

function LandingDesign1(props) {
    let { left, leftDynamic, right } = props.data;

    return (
        <section className="landing_section">
            <div className="landing_container">
                <div className="container_content">
                    <div className="landing_main_container landing1_container">
                        <div className="landing1_left landing_left ">
                            <div className="landing1_title">
                                <p>
                                    {left}
                                </p>
                                <p>
                                    <Typed
                                        strings={leftDynamic}
                                        typeSpeed={50}
                                        startDelay={500}
                                        backDelay={2000}
                                        backSpeed={50}
                                        showCursor={false}
                                        attr="value"
                                        loop >
                                        <input className="select" type="text" name="custom_text" />
                                    </Typed>
                                </p>
                            </div>
                            <div className="landing1_content">
                                <h1>
                                    {right}
                                </h1>
                            </div>
                        </div>
                        <div className="landing1_right">
                            <div className="landing1_right_item1">
                                <img src={`./assets/images/${getRandomImage(imageLeft)}.jpg`} alt="leftimage" />
                                <p className="landing1_right_letter1">
                                    D
                                </p>

                            </div>
                            <div className="landing1_right_item2">
                                <img src={`./assets/images/${getRandomImage(imageRight)}.jpg`} alt="rightimage" />
                                <p className="landing1_right_letter2">
                                    W
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LandingDesign1;