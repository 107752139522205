import React from 'react';

import logo from '../assets/svg/logo.svg';

import '../styles/home.css'

function Background(props) {
    return (
        <>
            <div className="bgContainer">
                <img src={logo} alt="" className="bgContainerIcon" />
            </div>
        </>
    )
}

export default Background;