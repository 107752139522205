import React, { useEffect } from 'react';

import Service from '../components/Service';
import { closeMenu } from '../components/helpers/func';

import '../styles/services.css'

function Services(props) {
    useEffect(() => {
        closeMenu();
    });
    return (
        <main className="content">
            <Service></Service>
        </main>
    )
}

export default Services;