import React from 'react';

import ContactForm from '../components/helpers/form';
import ScrollText from '../components/ScrollText';

import { closeMenu } from '../components/helpers/func';
import '../styles/contact.css';

class Contact extends React.Component {

    componentWillMount() {
        closeMenu();
        //document.body.classList.add("-contact");
    }

    componentWillUnmount() {
        //document.body.classList.remove("-contact");
    }

    render() {
        return (
            <main className="main_content">
                <section className="contact_section">
                    <div className="contact_container">
                        <div className="container_content">
                            <div className="contact_main_container">
                                <div className="contact_left">
                                    <p>
                                        <a href="mailto:hello@dotworld.in">
                                            <span className="line">
                                                <strong className="select">hello</strong>
                                                @dotworld.in
                                            </span>
                                        </a>
                                    </p>
                                </div>
                                <div className="contact_right">
                                    <p className="">Everything starts with a hello</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ScrollText text={"contact"}></ScrollText>
                <ContactForm></ContactForm>
                {/* <iframe className="map_section" name="google_maps" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBltX72NVZdlGxBjuawFyH4ITYPousIDOU&q=Dotworld+Technologies,Coimbatore&zoom=15&maptype=roadmap" frameBorder="0"></iframe> */}
                {/* <Background /> */}
            </main>
        )
    }
}

export default Contact;