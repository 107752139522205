import React from 'react';
import { TweenLite } from 'gsap';
import $ from "jquery";

import '../styles/cursor.css';

class Cursor extends React.Component {

    constructor(props) {
        super(props);
        this.cursor = null;
        this.body = $(document.body);
        this.tween = null;
        this.o = void 0;
        this.a = void 0;
        this.n = void 0;
        this.i = void 0;
    }

    componentDidMount() {
        // use the node ref to create the animation
        this.o = (this.cursor.offsetHeight, this.cursor.offsetWidth, document.body.clientWidth)
        this.a = document.body.clientHeight
        this.tween = function (e, o) {
            TweenLite.to(this.cursor, .2, {
                y: o,
                x: e
            })
        }
        this.addAllListeners();
    }

    addAllListeners = () => {
        var cursor = $(this.cursor);

        $(document.body).on("resize", function () {
            this.o = document.body.clientWidth;
            this.a = document.body.clientHeight;
        });

        $(document.body)
            .on("mousemove", this.onMouseMove)
            .on("mouseenter", "a,button,iframe,.partner", function () {
                cursor.addClass("-disabled")
            })
            .on("mouseleave", "a,button,iframe,.partner", function () {
                cursor.removeClass("-disabled")
            })
            .on("mouseenter", "[data-cursor]", function () {
                cursor.addClass($(this).data("cursor"))
            })
            .on("mouseleave", "[data-cursor]", function () {
                cursor.removeClass($(this).data("cursor"))
            })
            .on("mousedown", function () {
                cursor.addClass("-mousedown")
            })
            .on("mouseup", function () {
                cursor.removeClass("-mousedown")
            })
    }

    onMouseMove = (e) => {
        var r = e.clientX;
        var s = e.clientY;
        Math.abs(r - this.n) > 7 || Math.abs(s - this.i) > 7 ? this.cursor.classList.add("-move") : this.cursor.classList.remove("-move")
        if (r < 50 || r > this.o - 50 || s < 50 || s > this.a - 50) {
            this.cursor.classList.remove("-visible");
            this.cursor.classList.remove("-move");
            this.cursor.classList.remove("-mousedown")
        }
        else {
            this.cursor.classList.add("-visible")
        }
        this.n = r;
        this.i = s;
        this.tween(r, s)
    }

    render() {
        return (
            <div className="dw-cursor" ref={div => this.cursor = div}></div>
        )
    }
}

export default Cursor;