import React from 'react';

import { sendEmail } from '../helpers/func';
import '../../styles/form.css'

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errors: {
                name: '',
                email: '',
                description: '',
            },
            values: {
                name: '',
                email: '',
                description: '',
            },
            isSubmitting: false,
            touched: {
                name: false,
                email: false,
                description: false,
            },
            done: ""
        }
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            values: {
                ...this.state.values,
                [name]: value
            },
            touched: {
                ...this.state.touched,
                [name]: true
            }
        });
    }

    handleSubmit = (event) => {
        this.setState({
            isSubmitting: true,
        })
        this.handleBlur()
        this.setState({
            touched: {
                name: true,
                email: true
            }
        })
        if (this.state.isError) {
            return
        }

        sendEmail(this.state.values)
            .then((response) => response.json())
            .then(({ status, message }) => {
                if (status) {
                    this.setState({
                        values: {
                            name: '',
                            email: '',
                            description: '',
                        },
                        done: "Thank you! We will contact you shortly 😀"
                    })
                } else {
                    this.setState({ done: "Something wrong happened. (╯°□°)╯︵ ┻━┻"})
                    console.error(message);
                }

            })
            .catch(error => console.error(error));
    }

    handleBlur = () => {
        let isError = false;
        let { errors, values } = this.state;
        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        } else {
            errors.email = '';
        }
        if (!values.name) {
            errors.name = 'Required';
        } else {
            errors.name = '';
        }
        if (errors.name || errors.email) {
            isError = true;
        }
        this.setState({
            isError: isError,
            errors: errors,
            isSubmitting: false,
        })
    }

    render() {
        let { errors, touched, isSubmitting, done } = this.state;

        return (
            <section className="from_section">
                <div className="form_container">
                    <div className="form_content">
                        <div className="form_title">
                            <p>Let us know what you think</p>
                        </div>

                        <form className="contact_form">
                            <div className="input-container">
                                <input
                                    className="input-field"
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    value={this.state.values.name}
                                    autoComplete="off"
                                />
                                <span className="error">&nbsp;{errors.name && touched.name && errors.name}</span>
                            </div>

                            <div className="input-container">
                                <input
                                    className="input-field"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    value={this.state.values.email}
                                    autoComplete="off"
                                />
                                <span className="error">&nbsp;{errors.email && touched.email && errors.email}</span>
                            </div>

                            <div className="input-container">
                                <textarea
                                    className="input-field textarea"
                                    name="description"
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    value={this.state.values.description}
                                    placeholder="How can we help you ¯\_(ツ)_/¯">
                                </textarea>
                            </div>
                            <button type="button" onClick={this.handleSubmit} className="btn fill" disabled={isSubmitting}>Submit</button>
                            <span className="message_response">
                                {done && done}
                            </span>
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}

export default ContactForm;