import React from "react";

import "../styles/Loading.css";

const logo = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 56.14 56.14"
    style={{ enableBackground: "new 0 0 56.14 56.14" }}
  >
    <circle className="st0 path" cx="28.07" cy="28.07" r="27.69" />
    <g>
      <path
        className="st1 path asia"
        d="M2.65,22.85c-0.15,0.1-0.29,0.24-0.4,0.42c-1.1,1.76-1.96,3.93-1.33,6.59c0.63,2.67,1.26,3.92,2.83,3.92
               c1.57,0,1.96,1.33,1.81,1.96c-0.16,0.63-0.16,1.1,0.39,2.51c0.55,1.41-0.55,1.88,0.31,3.45c0.86,1.57,1.41,2.67,2.2,3.61
               c0.79,0.94,2.67,2.12,3.38,1.02c0.71-1.1,0.31-2.98,1.33-3.53c1.02-0.55,0.63-3.14,0.31-4.32c-0.31-1.18,2.28-2.04,3.14-4.16
               c0.86-2.12,0.71-2.67-0.63-2.28c-1.33,0.39-3.22-2.01-3.93-4.38c-0.71-2.37-0.31-1.9,1.18,0.53s1.41,3.38,2.98,2.59
               c1.57-0.78,4.4-1.65,4.24-3.22c-0.16-1.57-1.18-2.36-1.96-1.57s-2.51-1.57-2.28-2.28c0.24-0.71,1.57,0.31,2.59,1.1
               c1.02,0.79,1.96,0.86,3.3,0.71s1.57,0.79,2.36,1.81c0.78,1.02,1.1-0.91,1.81,0.33c0.71,1.24-0.55,2.02,0.79,3.91
               c1.33,1.88,2.12,2.28,2.36,0.47c0.24-1.81,0.47-2.56,1.65-3.52s1.88-2.68,2.98-1.35c1.1,1.33,0.08,2.9,1.81,3.45
               c1.73,0.55,1.88,1.02,3.61,1.65c1.73,0.63,2.2-1.65,1.1-3.06c-1.1-1.41,1.02-1.88,2.36-2.28c1.33-0.39,2.2-1.65,1.57-3.3
               c-0.63-1.65-2.75-4.24-1.57-4.79c1.18-0.55,2.2,0.24,2.59,1.65c0.39,1.41,1.65,0.24,0.31-1.73c-1.33-1.96,0.55-2.51,0.71-3.85
               c0.16-1.33-2.2-2.36-2.83-3.85c-0.63-1.49,0.71-1.57,2.04-2.28c1.33-0.71-7.62-6.12-8.48-6.28c-0.86-0.16-0.24,0.79-3.14,0.63
               c-2.9-0.16-3.9-1.02-6.11-1.02c-2.21,0-3.7,0.24-4.96,2.12c-1.26,1.88-0.47-0.86-2.2-0.08C19.13,4.98,19.44,5.53,17.87,6
               c-1.57,0.47-4.55,4.55-5.02,3.69c-0.47-0.86,1.41-2.43-0.63-1.88c-2.04,0.55-3.53,1.96-4.4,2.98c-0.86,1.02-2.28,2.98-1.33,3.3
               c0.94,0.31,1.65-0.86,2.59-2.59s1.57-0.79,0.86,0.39c-0.71,1.18-2.98,2.83-4.32,3.69c-1.33,0.86-1.88,2.12-1.73,3.45
               c0.16,1.33-1.06,1.72-1.26,2.28c-0.2,0.56,0.24,0.89,0.69,0.89s0.64-3.01,1.74-2.62c1.1,0.39,0.63,1.73,1.41,1.65
               c0.79-0.08-0.39-1.81,0.16-2.36C7.2,18.33,6.8,20.92,8.06,21c1.26,0.08,1.49-0.71,1.57-2.2c0.08-1.49,1.18-2.75,2.43-2.04
               c1.26,0.71,2.43,2.04,0.63,2.12c-1.81,0.08-3.96,1.45-2.54,2.23c1.41,0.78,2.54,0.98,1.13,1.93c-1.41,0.94-4,1.57-5.18,0.63
               c-0.82-0.66-2-1.2-2.89-1.04L2.65,22.85z M16.07,20.45c-1.41-0.39,0.16-1.49-0.55-2.2c-0.71-0.71,0.13-1.43,0.71-2.04
               c1.26-1.33,0.55,1.73,1.41,3.06C18.5,20.6,17.48,20.84,16.07,20.45z"
      />
      <path
        className="st1 path "
        d="M16.7,41.57c-0.98,1.11-2.04,0.16-1.65,1.33c0.39,1.18-0.77,2.04,0.2,2.43c0.98,0.39,0.9,0.08,1.6-1.65
               C17.56,41.96,17.32,40.86,16.7,41.57z"
      />
      <path
        className="st1 path"
        d="M48.41,18.01c0,1.2,0.24,1.73-0.31,2.35c-0.55,0.63-1.33,1.88-0.94,2.9c0.39,1.02,0.55-1.1,1.26-1.81
               c0.71-0.71,1.18-1.02,0.86-2.36C48.96,17.78,48.41,17.31,48.41,18.01z"
      />
      <path
        className="st1 path"
        d="M43.23,34.45c-0.51,1.07-1.73,0.42-1.73,1.62c0,1.2,1.2,3.14,1.83,1.88c0.63-1.26,1.05-1.31,1.26-2.56
               C44.8,34.13,43.65,33.56,43.23,34.45z"
      />
      <path
        className="st1 path"
        d="M36.48,34.24c0.06,0.91,0.99,1.26,1.73,2.62c0.73,1.36,1.47,2.46,2.09,2.51c0.63,0.05,4.61,1.2,4.4,0.05
               c-0.21-1.15-3.89-0.05-4.68-1.76C39.24,35.94,36.37,32.72,36.48,34.24z"
      />
      <path
        className="st1 path"
        d="M44.49,42.82c-0.98,0.85-2.35,0.52-2.98,1.99c-0.63,1.47-2.25,4.82-0.73,3.98c1.52-0.84,3.35-2.36,4.34-1.73
               c0.99,0.63,0.78,2.09,1.67,1.41c0.89-0.68,2.88-3.19,3.63-4.71c0.79-1.6,0.29-2.35,0.03-3.24c-0.26-0.89-0.68,0-1.36,0.73
               c-0.68,0.73,0.37-1.57-1.83-0.52C45.06,41.77,45.32,42.09,44.49,42.82z"
      />
      <path
        className="st1 path"
        d="M48.36,35.91c-0.58,0.58-0.47,1.1,0.47,1.47c0.94,0.37,1.26,1.73,1.73,1.2c0.47-0.52,1.52,0.52,1.47-0.52
               c-0.05-1.05-0.68-1.67-1.67-1.57C49.35,36.59,49.04,35.23,48.36,35.91z"
      />
      <path
        className="st1 path"
        d="M45.11,28.85c-0.37,1.07-0.31,1.78,0.47,2.25c0.79,0.47,1.2,1.15,0.68,1.73c-0.52,0.58,0.52,1.31,0.78,0.42
               c0.26-0.89-0.26-1.99-0.78-3.09C45.74,29.06,45.48,27.8,45.11,28.85z"
      />
      <path
        className="st1 path"
        d="M46.89,9.06c0,0,0,0.01,0,0.01c-0.15,0.63,0.32,1.72,1.05,2.6c0.73,0.89,1.45,3.14,1.43,2.2
               c-0.02-0.94,0.5-1.94-0.23-2.98C48.41,9.85,46.79,7.86,46.89,9.06z"
      />
    </g>
  </svg>
);

const mask = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 240.3 240.3"
    style={{ width: "300px" }}
  >
    <g id="Layer_1">
      <path
        className="st2 path1"
        d="M120.1,171.2c-9.9,0.1-19-3.1-27.2-8.4c-14-9-25.7-20.4-34.6-34.6c-0.9-1.4-2.5-2.6-4-3.5c-6.1-4-12.7-7.4-18.3-12
			c-4.1-3.4-7.8-7.9-10.1-12.7c-5.1-10.9,2-21.4,14.1-21c4.4,0.1,8.7,1.9,13.1,2.5c3.3,0.5,6.9,1,10,0.3
			c14.2-3.3,28.3-7.1,42.4-10.7c9.7-2.5,19.3-2.5,29,0c14.3,3.7,28.6,7.4,42.9,10.8c2.8,0.7,6.1,0.2,9.1-0.3c4.1-0.6,8-2.2,12.1-2.6
			c13.6-1.1,21.4,10.3,14.8,22.3c-3,5.3-7.5,10.6-12.6,13.7c-11.8,6.9-20.7,16.2-29.5,26.5c-7.6,8.8-17.3,15.9-26.8,22.7
			C137.6,169.2,129,171.2,120.1,171.2z M121,75c-3,0.3-5.2,0.2-7.2,0.7C98,79.5,82.2,83.3,66.5,87.4c-1.3,0.3-2.8,2.6-3,4.2
			c-0.8,6.9-1.3,13.9-1.8,20.9c-0.4,6,0.7,11.7,4.4,16.5c8.6,11.3,18.4,21.4,30.7,28.9c15.9,9.7,31.7,9.4,47.4-0.4
			c11.7-7.3,21.1-17.1,29.6-27.9c4.2-5.4,5.3-11.7,4.7-18.4c-0.5-6.3-1.2-12.6-1.5-18.9c-0.1-3.2-1.2-4.6-4.4-5.4
			c-13.5-3.2-27-6.9-40.5-10.2C128.2,75.8,124.2,75.5,121,75z M55.2,118c0.5-6.3,0.9-11.6,1.2-16.9c0.8-13.2,0.3-13.8-12.9-16
			c-4.6-0.7-9.2-0.6-12,3.9c-2.7,4.4-0.6,8.4,1.9,12C38.8,108.8,46.8,113.1,55.2,118z M185.1,118.5c5.9-4.2,11.5-7.7,16.5-11.8
			c2.8-2.4,5.3-5.6,6.9-8.9c3.4-7-0.5-13.1-8.2-12.6c-5.2,0.4-10.3,2.2-15.3,3.7c-0.9,0.3-2,2.2-2,3.2
			C183.6,100.5,184.4,108.9,185.1,118.5z"
      />
      <path
        className="st2 path1"
        d="M75.7,118.2c29.7,6,59.4,5.9,89-0.1C143.4,132.8,99.5,132.9,75.7,118.2z"
      />
      <path
        className="st2 path1"
        d="M155.7,137.5c-20.6,17.9-53.1,17.8-70.4,0.3C122.7,146.3,122.7,146.3,155.7,137.5z"
      />
      <path
        className="st2 path1"
        d="M84.1,103.8c24.2-4.5,47.8-4.5,71.9,0C131.9,108.4,108.3,108.4,84.1,103.8z"
      />
      <text
        xmlns="http://www.w3.org/2000/svg"
        className="cls-1"
        transform="translate(35.63 207.31)"
      >
        WEAR A MASK
      </text>
    </g>
  </svg>
);

function Loading(props) {
  /*     setTimeout(() => {
           props.loaded(false)
       }, 2000)
    */

  function mobilecheck() {
    var check = false;
    /*eslint no-useless-escape: "off"*/
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  return mobilecheck() ? null : (
    <div id="landing" className="cb-loader cb-loader_overlay -front -visible">
      <div className="cb-loader_overlay-img">
        {Math.round(Math.random()) === 0 ? mask : logo}
      </div>
    </div>
  );
}

export default Loading;
